var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("项目管理")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-card",
            { attrs: { title: "项目列表" } },
            [
              _c(
                "div",
                { staticClass: "flex justify-between m-b-16" },
                [
                  _c("a-input-search", {
                    staticStyle: { width: "400px" },
                    attrs: { placeholder: "搜索项目名称" },
                    on: { search: _vm.searchClick },
                    model: {
                      value: _vm.queryModel.params,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryModel, "params", $$v)
                      },
                      expression: "queryModel.params",
                    },
                  }),
                  _c(
                    "jtl-button",
                    {
                      staticClass: "table-header-button",
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.addClick(new _vm.ProjectEntityModel())
                        },
                      },
                    },
                    [_vm._v("添加项目")]
                  ),
                ],
                1
              ),
              _c("a-table", {
                attrs: {
                  loading: _vm.listLoading,
                  columns: _vm.tableColumns,
                  "data-source": _vm.listData,
                  pagination: _vm.Pagination,
                  scroll: _vm.tableScroll,
                  "row-key": _vm.TableRowKey,
                  size: _vm.TableSize,
                },
                on: { change: _vm.tableChange },
                scopedSlots: _vm._u([
                  {
                    key: "name",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-link",
                            on: {
                              click: function ($event) {
                                return _vm.selectProjectClick(record)
                              },
                            },
                          },
                          [_vm._v(_vm._s(text))]
                        ),
                      ]
                    },
                  },
                  {
                    key: "adminUserNames",
                    fn: function (text, record) {
                      return _vm._l(record.adminUserNames, function (item) {
                        return _c(
                          "a-tag",
                          { key: item, attrs: { color: "blue" } },
                          [_vm._v(_vm._s(item))]
                        )
                      })
                    },
                  },
                  {
                    key: "action",
                    fn: function (text, record) {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.editClick(record, true)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: "确认删除?",
                              "ok-text": "确认",
                              "cancel-text": "取消",
                            },
                            on: {
                              confirm: function ($event) {
                                return _vm.deleteClick(record)
                              },
                            },
                          },
                          [
                            _c("a", { staticClass: "jtl-del-link" }, [
                              _vm._v("删除"),
                            ]),
                          ]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-del-link",
                            on: {
                              click: function ($event) {
                                return _vm.configClick(record)
                              },
                            },
                          },
                          [_vm._v("资源管理")]
                        ),
                        _c("a-divider", { attrs: { type: "vertical" } }),
                        _c(
                          "a",
                          {
                            staticClass: "jtl-edit-link",
                            on: {
                              click: function ($event) {
                                return _vm.initClick(record)
                              },
                            },
                          },
                          [_vm._v("应用初始化")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.projectFormDialogOK },
      }),
      _c("corp-detail-drawer", { ref: "corpDetailDrawer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }